import React from 'react';
import { Link } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import '../styles/LandingPage.css';
// import { BsLightning } from 'react-icons/bs'; // Bootstrap icon
import { FaBolt } from 'react-icons/fa'; // Font Awesome icon
// import { IoFlashOutline } from 'react-icons/io5'; // Ionicons icon
import logo from '../logo4.png';

const markdownContent = `
## Overview

ResuMatch AI is an innovative web application designed to help job seekers optimize their resumes for specific 
job descriptions. Utilizing OpenAI's GPT models, ResuMatch AI analyzes both 
the user's resume and the target job description, providing tailored suggestions to enhance the resume's 
relevance and impact. Of course, there is a dependence on AI Quality: the effectiveness of suggestions relies 
on the underlying AI models but prompts have been designed to specifically address this concern.

## Key Features

1. Resume Parsing: Extracts and structures key information from uploaded resumes.
2. Job Description Analysis: Identifies critical skills and requirements from job postings.
3. AI-Powered Suggestions: Generates specific, actionable recommendations to align the resume with job requirements.
4. Real-time Updating: Allows users to implement suggestions and see changes instantly.
5. Multiple AI Model Support: Utilizes both GPT-4 and GPT-4o-mini for varied levels of analysis.
6. User-friendly Interface: Features a split-screen layout for easy comparison and editing.

### API Key & Data Privacy
This is a Bring Your Own Key (BYOK) application. 
In order to run the application you must have an OpenAI API key. 
The key is NOT stored, neither is your Resume.


## Innovative Aspects

1. Ethical AI Application: Unique focus on enhancing existing information without fabrication, addressing a key concern in AI-assisted job applications.
2. Dual Model Approach: Use of two different GPT models to balance between quick iterations and in-depth analysis.
3. Interactive Suggestion Implementation: Split-screen interface provide an innovative user experience.
4. Transparency in AI Decisions: Provides rationales for suggestions, enhancing user trust and understanding.
5. AI-Driven Development (see next section)

## AI-Driven Development

ResuMatch AI is a unique application that showcases the power of AI-driven development. 
Every line of code in this app was written by Claude 3.5 Sonnet, an advanced AI assistant created by Anthropic. 
As a human developer, my role was to guide the development process, provide requirements, and make strategic 
decisions. This collaboration between human creativity and AI capabilities has resulted in a powerful tool 
that leverages cutting-edge language models to help job seekers optimize their resumes. 
By using ResuMatch AI, you're not just experiencing a useful application – you're witnessing the future of 
software development, where AI and human expertise combine to create innovative solutions.

## Future Developments

1. Currently only supports txt and docx resume formats. Add PDF support.
2. ATS (Applicant Tracking System): Add ATS component.
3. Users need to manually input job descriptions. Add integration with Job Board(s).
4. May not account for industry-specific nuances or unconventional career paths.
5. Privacy Concerns: While no data is currently stored by the application, 
handling of sensitive personal data will remain an area of close attention in future developments.

`;

const LandingPage = () => {
  return (
    <div className="landing-page">
    <header>
    <div className="header-content">
        <img src={logo} alt="ResuMatch Logo" className="landing-logo" />
        <h1>ResuMatch AI</h1>
    </div>
    <div className="header-content">
        <h2>AI-Powered Resume Enhancement Application</h2>
    </div>
    </header>
      <main>
        <Link to="/app" className="cta-button">
                <FaBolt />
            Get Started
        </Link>
        <ReactMarkdown>{markdownContent}</ReactMarkdown>
      </main>
      <footer>
        <p>&copy; 2024 Raoul Biagioni. All rights reserved. (linkedin.com/in/raoulbia/)</p>
      </footer>
    </div>
  );
};

export default LandingPage;