// src/components/Suggestions.js
import React, { useState } from 'react';

function Suggestions({ suggestions, resume, jobDescription, updateSuggestion, isLoading }) {
  const [expandedExample, setExpandedExample] = useState(null);

  const toggleExample = (index) => {
    setExpandedExample(expandedExample === index ? null : index);
  };

  const renderSuggestion = (suggestion, index, title) => (
    <li key={index} className="suggestion">
      <h3>{title}</h3>
      <div className="suggestion__content">
        <p>{suggestion.suggestion}</p>
      </div>
      <div className="suggestion__actions">
        <button onClick={() => toggleExample(index)} className="suggestion__toggle-button">
          {expandedExample === index ? 'Hide Example' : 'Show Example'}
        </button>
      </div>
      {expandedExample === index && (
        <div className="suggestion__example">
          <h4>Example:</h4>
          <p>{suggestion.example}</p>
        </div>
      )}
    </li>
  );

  return (
    <div className="suggestions">
      <h2>Suggestions</h2>
      <div className="suggestions__content">
        {isLoading ? (
          <div className="spinner">
            <div className="spinner-inner"></div>
          </div>
        ) : suggestions && Object.keys(suggestions).length > 0 ? (
          <ul className="suggestions__list">
            {suggestions.professionalSummary && renderSuggestion(suggestions.professionalSummary, 'summary', 'Professional Summary')}
            {suggestions.roleSuggestions && suggestions.roleSuggestions.map((roleSuggestion, index) => 
              renderSuggestion(roleSuggestion, index, `${roleSuggestion.role} - ${roleSuggestion.company}`)
            )}
          </ul>
        ) : (
          <p className="suggestions__empty">No suggestions yet. Click "Generate Suggestions" to get started.</p>
        )}
      </div>
    </div>
  );
}

export default Suggestions;