import React, { useState, useEffect, useCallback } from 'react';
import JobDescription from './JobDescription';
import Resume from './Resume';
import Suggestions from './Suggestions';
import { generateSuggestions } from '../services/api';
import './../styles/main.css';

function SplitScreen({ isJobDescriptionCollapsed, toggleJobDescription, model, apiKey, resume, setResume, onFileUpload }) {
  const [jobDescription, setJobDescription] = useState('');
  const [suggestions, setSuggestions] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleGenerateSuggestions = useCallback(async () => {
    console.log('handleGenerateSuggestions called');
    console.log('Job Description:', jobDescription);
    console.log('Resume:', resume);
    console.log('Model:', model);
    console.log('API Key present:', !!apiKey);

    if (!jobDescription.trim() || !resume.trim()) {
      console.log('Missing job description or resume');
      alert('Please provide both a job description and a resume before generating suggestions.');
      return;
    }
    if (!apiKey) {
      console.log('Missing API key');
      alert('Please enter an API key in the settings before generating suggestions.');
      return;
    }
    setIsLoading(true);
    try {
      console.log('Calling generateSuggestions');
      const result = await generateSuggestions(jobDescription, resume, model, apiKey);
      console.log('Suggestions generated:', result);
      setSuggestions(result);
      toggleJobDescription(true); // Automatically collapse job description
    } catch (error) {
      console.error('Failed to generate suggestions:', error);
      alert('Failed to generate suggestions. Please try again.');
    } finally {
      setIsLoading(false);
    }
  }, [jobDescription, resume, model, toggleJobDescription, apiKey]);

  const handleResumeChange = useCallback((newResume) => {
    setResume(newResume);
    // We can add any necessary parsing logic here, but without API calls
  }, [setResume]);

  useEffect(() => {
    const generateSuggestionsListener = (event) => {
      if (event.detail && event.detail.apiKey) {
        handleGenerateSuggestions();
      } else {
        alert('API key is missing. Please check your settings.');
      }
    };
    window.addEventListener('generate-suggestions', generateSuggestionsListener);

    return () => {
      window.removeEventListener('generate-suggestions', generateSuggestionsListener);
    };
  }, [handleGenerateSuggestions]);

  return (
    <div className="split-screen">
      <div className="split-screen__left">
        <div className={`split-screen__left-top ${isJobDescriptionCollapsed ? 'collapsed' : ''}`}>
          <JobDescription
            jobDescription={jobDescription}
            setJobDescription={setJobDescription}
            isCollapsed={isJobDescriptionCollapsed}
          />
        </div>
        <div className="split-screen__left-bottom">
          <Suggestions
            suggestions={suggestions}
            resume={resume}
            jobDescription={jobDescription}
            isLoading={isLoading}
          />
        </div>
      </div>
      <div className="split-screen__right">
        <Resume
          resume={resume}
          setResume={handleResumeChange}
          onParseResume={handleResumeChange}  // This now just updates the resume without triggering suggestions
          apiKey={apiKey}
          onFileUpload={onFileUpload}
        />
      </div>
    </div>
  );
}

export default SplitScreen;