import React from 'react';
import '../styles/MobileMessage.css';

const MobileMessage = () => {
  return (
    <div className="mobile-message">
      <div className="mobile-message-content">
        <h1>Desktop View Required</h1>
        <p>This site is not suitable for viewing on mobile devices. Please use a Desktop PC.</p>
      </div>
    </div>
  );
};

export default MobileMessage;