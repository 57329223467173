import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { House, FileText, UploadSimple, LightbulbFilament, DownloadSimple, Gear } from 'phosphor-react';
import logo from './../logo4.png';
import './../styles/main.css';

function Sidebar({ onToggleDescription, onUploadResume, onDownloadResume, onGenerateSuggestions, onModelChange, currentModel, onApiKeyChange, currentApiKey }) {
  const [showSettings, setShowSettings] = useState(false);
  const [apiKey, setApiKey] = useState(currentApiKey);
  const navigate = useNavigate();

  useEffect(() => {
    setApiKey(currentApiKey);
  }, [currentApiKey]);

  const handleModelChange = (event) => {
    onModelChange(event.target.value);
  };

  const handleApiKeyChange = (event) => {
    setApiKey(event.target.value);
  };

  const saveSettings = () => {
    onApiKeyChange(apiKey);
    setShowSettings(false);
  };

  const handleGenerateSuggestions = () => {
    if (!apiKey) {
      alert('Please enter an API key in the settings before generating suggestions.');
      return;
    }
    onGenerateSuggestions();
  };

  const handleHomeClick = () => {
    navigate('/');
  };

  const handleUploadResume = () => {
    if (!apiKey) {
      alert('Please enter an API key in the settings before uploading a resume.');
      return;
    }
    onUploadResume();  // Open the file input dialog in App.js
  };

  return (
    <>
      <div className="sidebar">
        <div className="sidebar-top">
          <img src={logo} alt="ResuMatch Logo" className="app-logo" />
          <button title="Home" onClick={handleHomeClick}><House size={24} /></button>
          <button title="Toggle Job Description" onClick={onToggleDescription}><FileText size={24} /></button>
          <button title="Upload Resume" onClick={handleUploadResume}><UploadSimple size={24} /></button>
          <button title="Generate Suggestions" onClick={handleGenerateSuggestions} ><LightbulbFilament size={24} /></button>
          <button title="Download Resume" onClick={onDownloadResume}><DownloadSimple size={24} /></button>
        </div>
        <div className="sidebar-bottom">
          <button title="Settings" onClick={() => setShowSettings(!showSettings)} className={showSettings ? 'active' : ''}>
            <Gear size={24} />
          </button>
        </div>
      </div>
      {showSettings && (
        <>
          <div className="modal-overlay" onClick={() => setShowSettings(false)}></div>
          <div className="settings-modal">
            <h3>Settings</h3>
            <div className="settings-content">
              <label htmlFor="model-select">Select Model:</label>
              <select
                id="model-select"
                value={currentModel}
                onChange={handleModelChange}
                className="model-select"
              >
                <option value="gpt-4o-mini">GPT-4O-Mini (Faster, cost-effective)</option>
                <option value="gpt-4">GPT-4 (More powerful, slower)</option>
              </select>
              <label htmlFor="api-key-input">API Key:</label>
              <input
                id="api-key-input"
                type="password"
                value={apiKey}
                onChange={handleApiKeyChange}
                placeholder="Enter your API key"
                className="api-key-input"
              />
            </div>
            <button onClick={saveSettings} className="save-button">Save</button>
            <button onClick={() => setShowSettings(false)} className="close-button">Cancel</button>
          </div>
        </>
      )}
    </>
  );
}

export default Sidebar;