import React, { useRef, useState, useEffect } from 'react';

function Resume({ resume, setResume, onParseResume, apiKey, onFileUpload }) {
  const fileInputRef = useRef(null);
  const [localResume, setLocalResume] = useState(resume);

  useEffect(() => {
    setLocalResume(resume);
  }, [resume]);

  const handleResumeChange = (event) => {
    const text = event.target.value;
    setLocalResume(text);
    setResume(text);
    // onParseResume(text);  // Trigger parsing if needed
  };

  return (
    <div className="resume">
      <h2>Resume</h2>
      <div className="upload-area">
        <input
          id="resume-file-input"
          type="file"
          ref={fileInputRef}
          style={{ display: 'none' }}
          onChange={onFileUpload}  // Call the file upload handler from App.js
          accept=".txt,.docx"
        />
        {!localResume ? (
          <>
            <p>Upload your resume...</p>
            <button onClick={() => {
              if (!apiKey) {
                alert('Please enter an API key in the settings before uploading a resume.');
                return;
              }
              fileInputRef.current.click();
            }} className="upload-button">
              Upload Resume
            </button>
          </>
        ) : (
          <textarea
            className="resume__content"
            value={localResume}
            onChange={handleResumeChange}
            placeholder="Enter or paste your resume here..."
          />
        )}
      </div>
    </div>
  );
}

export default Resume;
