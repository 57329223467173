import React from 'react';

function JobDescription({ jobDescription, setJobDescription, isCollapsed }) {
  return (
    <div className={`job-description ${isCollapsed ? 'collapsed' : ''}`}>
      <div className="job-description__header">
        <h2>Job Description</h2>
      </div>
      <div className="job-description__content">
        {isCollapsed ? (
          <div className="job-description__summary">
            {jobDescription.slice(0, 100)}...
          </div>
        ) : (
          <div className="upload-area">
            <textarea
              value={jobDescription}
              onChange={(e) => setJobDescription(e.target.value)}
              placeholder="Paste job description here..."
              rows="10"
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default JobDescription;
