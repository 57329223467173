// src/services/api.js
const API_URL = process.env.REACT_APP_API_URL;
console.log('API URL:', API_URL);

// ... rest of the file remains the same ...

export const generateSuggestions = async (jobDescription, resume, model, apiKey) => {
  try {
    console.log('Sending request to generate suggestions');
    console.log('Job Description:', jobDescription.substring(0, 100) + '...');
    console.log('Resume:', resume.substring(0, 100) + '...');
    console.log('Selected model:', model);

    const response = await fetch(`${API_URL}/api/suggestions`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${apiKey}`
      },
      body: JSON.stringify({ jobDescription, resume, model }),
    });

    console.log('Response status:', response.status);

    if (!response.ok) {
      const errorText = await response.text();
      console.error('Error response:', errorText);
      throw new Error(`Failed to generate suggestions: ${response.status} ${errorText}`);
    }

    const data = await response.json();
    console.log('Suggestions generated successfully:', data);
    return data;
  } catch (error) {
    console.error('Error generating suggestions:', error);
    throw error;
  }
};

export const parseResume = async (resume, apiKey) => {
  try {
    const resumeText = typeof resume === 'string' ? resume : JSON.stringify(resume);
    
    console.log('Sending request to parse resume');
    console.log('Resume:', resumeText.substring(0, 100) + '...');

    const response = await fetch(`${API_URL}/api/parseResume`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${apiKey}`
      },
      body: JSON.stringify({ resume: resumeText }),
    });

    console.log('Parse resume response status:', response.status);

    if (!response.ok) {
      const errorText = await response.text();
      console.error('Parse resume response:', response.status, errorText);
      throw new Error(`Failed to parse resume: ${response.status} ${errorText}`);
    }

    const data = await response.json();
    console.log('Parsed resume data:', data);
    return data;
  } catch (error) {
    console.error('Error parsing resume:', error);
    throw error;
  }
};
