import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import JSZip from 'jszip';
import SplitScreen from './components/SplitScreen';
import Sidebar from './components/Sidebar';
import LandingPage from './components/LandingPage';
import MobileMessage from './components/MobileMessage';
import './styles/main.css';

function App() {
  const [isJobDescriptionCollapsed, setIsJobDescriptionCollapsed] = useState(false);
  const [model, setModel] = useState('gpt-4o-mini'); // Default model
  const [apiKey, setApiKey] = useState(process.env.OPENAI_API_KEY || '');
  const [resume, setResume] = useState('');
  const fileInputRef = useRef(null);

  useEffect(() => {
    setApiKey(process.env.OPENAI_API_KEY || '');
  }, []);

  const handleToggleDescription = () => {
    setIsJobDescriptionCollapsed(prev => !prev);
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    let text;
    try {
      if (file.name.endsWith('.docx')) {
        const arrayBuffer = await file.arrayBuffer();
        const zip = await JSZip.loadAsync(arrayBuffer);
        const content = await zip.file("word/document.xml").async("text");
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(content, "text/xml");
        const paragraphs = xmlDoc.getElementsByTagName("w:p");
        text = Array.from(paragraphs).map(p =>
          Array.from(p.getElementsByTagName("w:t"))
            .map(t => t.textContent)
            .join('')
        ).join('\n');
      } else if (file.name.endsWith('.txt')) {
        text = await file.text();
      } else {
        throw new Error('Unsupported file format');
      }
      setResume(text);
    } catch (error) {
      console.error('Error processing file:', error);
      alert('Error reading file. Please make sure it\'s a valid .txt or .docx file.');
    }
  };

  const handleDownloadResume = () => {
    if (!resume || resume.trim() === '') {
      alert('No resume content to download. Please enter or upload a resume first.');
      return;
    }
  
    const element = document.createElement("a");
    const file = new Blob([resume], { type: 'text/plain' });
    element.href = URL.createObjectURL(file);
    element.download = "modified_resume.txt";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  const handleGenerateSuggestions = () => {
    if (!apiKey) {
      alert('Please enter an API key in the settings before generating suggestions.');
      return;
    }
    // Dispatch custom event to trigger suggestions generation
    window.dispatchEvent(new CustomEvent('generate-suggestions', { detail: { model, apiKey } }));
  };

  const handleModelChange = (newModel) => {
    setModel(newModel);
    console.log(`Model changed to: ${newModel}`);
  };

  const handleApiKeyChange = (newApiKey) => {
    setApiKey(newApiKey);
    console.log('API key updated');
  };

  const toggleJobDescription = (forceCollapse) => {
    if (forceCollapse !== undefined) {
      setIsJobDescriptionCollapsed(forceCollapse);
    } else {
      setIsJobDescriptionCollapsed(prev => !prev);
    }
  };

  return (
    <Router>
      <MobileMessage />
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/app" element={
          <div className="App">
            <header className="app-header">
              <div>
                <h1>ResuMatch AI</h1>
                <p className="tag-line">AI-powered resume optimization for your dream job</p>
              </div>
            </header>
            <div className="main-content">
              <Sidebar
                onToggleDescription={handleToggleDescription}
                onUploadResume={() => fileInputRef.current.click()}
                onDownloadResume={handleDownloadResume}
                onGenerateSuggestions={handleGenerateSuggestions}
                onModelChange={handleModelChange}
                onApiKeyChange={handleApiKeyChange}
                currentModel={model}
                currentApiKey={apiKey}
              />
              <SplitScreen
                isJobDescriptionCollapsed={isJobDescriptionCollapsed}
                toggleJobDescription={toggleJobDescription}
                model={model}
                apiKey={apiKey}
                resume={resume}
                setResume={setResume}
                onFileUpload={handleFileUpload}
              />
            </div>
            <footer>
              <p>&copy; 2024 Raoul Biagioni. All rights reserved. (linkedin.com/in/raoulbia/)</p>
            </footer>
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: 'none' }}
              onChange={handleFileUpload}
              accept=".txt,.docx"
            />
          </div>
        } />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Router>
  );
}

export default App;